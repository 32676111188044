<template>
  <div class="bread-crumb-content pr16 pt16 pl16">
    <div class="bread-crumb-box">
      <a-breadcrumb>
        <a-breadcrumb-item id="quipment_setting_prePage" @click.native="goBack">
          <span class="equipment">{{ title }}</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <span>{{ childTitle }}</span>
        </a-breadcrumb-item>
      </a-breadcrumb>
      <div class="bread-crumb-box-content">
        <div>
          <span v-if="!$slots.title" class="bread-crumb__title">{{
            childTitle
          }}</span>
          <slot name="title" v-else></slot>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    childTitle: {
      type: String,
      default: '',
    },
  },
  methods: {
    goBack() {
      this.$emit('goBackPage')
    },
  },
}
</script>

<style scoped lang="less">
.bread-crumb-content {
  position: sticky;
  top: 0px;
  z-index: 500;
  // width: 100%;
  // min-width: 1655px;
  .bread-crumb-box {
    box-sizing: border-box;
    // width: 1440px;
    height: 112px;
    margin: 0 auto;
    // padding: 22px 16px 0 16px;
    // padding: 22px 32px 0 23px;
    box-shadow: 0px 4px 10px 0px rgba(196, 196, 196, 0.21);
    background-color: #fff;
    /deep/.ant-breadcrumb {
      padding: 22px 32px 0 23px;
    }
    .equipment {
      font-family: PingFang SC;
      cursor: pointer;
    }
    .bread-crumb-box-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      padding: 0 32px 0 23px;
      border-top: 1px solid #e7e7e7;
      .bread-crumb__title {
        color: #000;
        font-size: 24px;
      }
    }
  }
}
</style>
